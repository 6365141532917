<template>
  <div class="News">
    <h3 class="null" v-if="newList.length <= 0">暂无资讯...</h3>
    <ul class="content" v-else>
      <li
        v-for="item in newList"
        :key="item.news_id"
        @click="ToDetailHandle(item)"
      >
        <div class="left">
          <img :src="picURL+item.cover_img" alt="" />
        </div>
        <div class="right">
          <p>
            <span>{{ item.addtime }}</span>
          </p>
          <h3>{{ item.title }}</h3>
          <div class="span">阅读量：&nbsp; {{ item.read_num }}</div>
        </div>
      </li>
    </ul>
    
  </div>
</template>

<script>
import { getNewList } from "../API/new";
export default {
  data() {
    return {
      newList: [],
      picURL: "http://www.gyycbj.com",
    };
  },

  created() {
    this.getNewHandle();
  },
  mounted() {},
  methods: {
    async getNewHandle() {
      const { data: res } = await getNewList();
      this.newList = res.data;
    },
    ToDetailHandle(item) {
      sessionStorage.setItem('NEWSID',item.news_id)
      this.$router.push({
        path: `NewsDetails/${item.news_id}`,
      });
    },
  },
};
</script>

<style scoped lang="scss">
.News {
  .null{
    text-align: center;
    font-size: 30px;
    padding: 200px 0;
  }
  .content {
    width: 1200px;
    margin: 0px auto;
    padding-top: 80px;
    margin-bottom: 180px;
    @media screen and (max-width: 540px) {
      width: 100%;
      padding: 20px 17px 40px 17px;
      box-sizing: border-box;
      margin: 0;
    }
    li {
      display: flex;
      margin-bottom: 20px;
      align-items: center;
      cursor: pointer;
      .left {
        width: 240px;
        height: 180px;
        margin-right: 30px;
        @media screen and (max-width: 540px) {
          flex-basis: 100px;
          height: 80px;
          margin-right: 10px;
        }
        img {
          width: 100%;
          height: 100%;
        }
      }
      .right {
        @media screen and (max-width: 540px) {
            width: 250px;
          }
        p {
          display: block;
          font-size: 18px;
          color: #999999;
          @media screen and (max-width: 540px) {
            font-size: 12px;
          }
        }
        h3 {
          padding: 17px 0;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          @media screen and (max-width: 540px) {
            padding: 5px 0 10px 0;
            font-size: 14px;
          }
        }
        .span {
          display: block;
          font-size: 18px;
          color: #999999;
          @media screen and (max-width: 540px) {
            font-size: 12px;
          }
        }
      }
    }
  }
}
</style>
